@use "settings-general" as general;

/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
COMPONENT SETTINGS
----------------------------------------
Read more about settings and
USWDS style tokens in the documentation:
https://designsystem.digital.gov/design-tokens
----------------------------------------
*/

// Accordion
$theme-accordion-border-width: 0.5 !default;
$theme-accordion-border-color: "base-lightest" !default;
$theme-accordion-font-family: "body" !default;

// Alert
$theme-alert-bar-width: 1 !default;
$theme-alert-font-family: "ui" !default;
$theme-alert-icon-size: 4 !default;
$theme-alert-padding-x: 2.5 !default;
$theme-alert-padding-y: 2 !default;
$theme-alert-text-color: default !default;
$theme-alert-text-reverse-color: default !default;
$theme-alert-link-color: default !default;
$theme-alert-link-reverse-color: default !default;

// Banner
$theme-banner-background-color: "base-lightest" !default;
$theme-banner-font-family: "ui" !default;
$theme-banner-link-color: default !default;
$theme-banner-max-width: "desktop" !default;

// Breadcrumb
$theme-breadcrumb-background-color: "default" !default;
$theme-breadcrumb-font-size: "sm" !default;
$theme-breadcrumb-font-family: "body" !default;
$theme-breadcrumb-link-color: default !default;
$theme-breadcrumb-min-width: "mobile-lg" !default;
$theme-breadcrumb-padding-bottom: 2 !default;
$theme-breadcrumb-padding-top: 2 !default;
$theme-breadcrumb-padding-x: 0 !default;
$theme-breadcrumb-separator-color: "base" !default;

// Button
$theme-button-font-family: "ui" !default;
$theme-button-border-radius: "md" !default;
$theme-button-small-width: 6 !default;
$theme-button-stroke-width: 2px !default;

// Card
$theme-card-border-color: "base-lighter" !default;
$theme-card-border-radius: "lg" !default;
$theme-card-border-width: 2px !default;
$theme-card-gap: 2 !default;
$theme-card-flag-min-width: "tablet" !default;
$theme-card-flag-image-width: "card-lg" !default;
$theme-card-font-family: "body" !default;
$theme-card-header-typeset: "heading", "lg", 2 !default;
$theme-card-margin-bottom: 4 !default;
$theme-card-padding-perimeter: 3 !default;
$theme-card-padding-y: 2 !default;

// Collection
$theme-collection-font-family: "ui" !default;
$theme-collection-header-typeset: "ui", "md", 3 !default;

// Footer
$theme-footer-font-family: "body" !default;
$theme-footer-max-width: "desktop" !default;

// Form and input
$theme-checkbox-border-radius: "sm" !default;
$theme-form-font-family: "ui" !default;
$theme-input-background-color: default !default;
$theme-input-line-height: 3 !default;
$theme-input-max-width: "mobile-lg" !default;
$theme-input-select-border-width: 2px !default;
$theme-input-select-size: 2.5 !default;
$theme-input-state-border-width: 0.5 !default;
$theme-input-tile-border-radius: "md" !default;
$theme-input-tile-border-width: 2px !default;

// Header
$theme-header-font-family: "ui" !default;
$theme-header-logo-text-width: 33% !default;
$theme-header-max-width: "desktop" !default;
$theme-header-min-width: "desktop" !default;

// Hero
$theme-hero-image: "#{general.$theme-image-path}/hero.jpg" !default;

// Icon List
$theme-icon-list-font-family: "body" !default;
$theme-icon-list-title-font-family: "heading" !default;

// Identifier
$theme-identifier-background-color: "base-darkest" !default;
$theme-identifier-font-family: "ui" !default;
$theme-identifier-identity-domain-color: "base-light" !default;
$theme-identifier-max-width: "desktop" !default;
$theme-identifier-primary-link-color: default !default;
$theme-identifier-secondary-link-color: "base-light" !default;

// In-Page Navigation
$theme-in-page-nav-background-color: "default" !default;
$theme-in-page-nav-background-padding: 2 !default;
$theme-in-page-nav-background-radius: "lg" !default;
$theme-in-page-nav-bar-color: "default" !default;
$theme-in-page-nav-bar-width: "05" !default;
$theme-in-page-nav-font-family: "ui" !default;
$theme-in-page-nav-link-color: "default" !default;
$theme-in-page-nav-main-content-max-width: "desktop" !default;
$theme-in-page-nav-margin-left: 4 !default;
$theme-in-page-nav-margin-top: 2.5 !default;
$theme-in-page-nav-top: 4 !default;

// Modal
$theme-modal-border-radius: "lg" !default;
$theme-modal-default-max-width: "mobile-lg" !default;
$theme-modal-lg-content-max-width: "tablet" !default;
$theme-modal-lg-max-width: "tablet-lg" !default;

// Pagination
$theme-pagination-background-color: "default" !default;
$theme-pagination-breakpoint: "tablet" !default;
$theme-pagination-button-border-radius: "md" !default;
$theme-pagination-button-border-width: 1px !default;
$theme-pagination-font-family: "ui" !default;

// Process List
$theme-process-list-counter-background-color: "white" !default;
$theme-process-list-counter-border-color: "ink" !default;
$theme-process-list-counter-border-width: 0.5 !default;
$theme-process-list-counter-font-family: "ui" !default;
$theme-process-list-counter-font-size: "lg" !default;
$theme-process-list-counter-gap-color: "white" !default;
$theme-process-list-counter-gap-width: 0.5 !default;
$theme-process-list-counter-size: 5 !default;
$theme-process-list-counter-text-color: "ink" !default;
$theme-process-list-connector-color: "primary-lighter" !default;
$theme-process-list-connector-width: 1 !default;
$theme-process-list-font-family: "ui" !default;
$theme-process-list-font-size: "sm" !default;
$theme-process-list-heading-color: "ink" !default;
$theme-process-list-heading-font-family: "ui" !default;
$theme-process-list-heading-font-size: "lg" !default;

// Navigation
$theme-navigation-font-family: "ui" !default;
$theme-megamenu-columns: 3 !default;

// Search
$theme-search-font-family: "ui" !default;
$theme-search-min-width: 27ch !default;

// Sidenav
$theme-sidenav-current-border-width: 0.5 !default;
$theme-sidenav-font-family: "ui" !default;

// Site Alert
$theme-site-alert-max-width: "desktop" !default;

// Step indicator
$step-indicator-background-color: "white" !default;
$theme-step-indicator-counter-gap: 0.5 !default;
$theme-step-indicator-counter-border-width: 0.5 !default;
$theme-step-indicator-font-family: "ui" !default;
$theme-step-indicator-heading-color: "ink" !default;
$theme-step-indicator-heading-font-family: "ui" !default;
$theme-step-indicator-heading-font-size: "lg" !default;
$theme-step-indicator-heading-font-size-small: "md" !default;
$theme-step-indicator-label-font-size: "sm" !default;
$theme-step-indicator-min-width: "tablet" !default;
$theme-step-indicator-segment-color-pending: "gray-40" !default;
$theme-step-indicator-segment-color-complete: "primary-darker" !default;
$theme-step-indicator-segment-color-current: "primary" !default;
$theme-step-indicator-segment-gap: 2px !default;
$theme-step-indicator-segment-height: 1 !default;
$theme-step-indicator-text-pending-color: "base-dark" !default;

// Summary box
$theme-summary-box-background-color: "info-lighter" !default;
$theme-summary-box-border-color: "info-light" !default;
$theme-summary-box-border-width: 1px !default;
$theme-summary-box-border-radius: "md" !default;
$theme-summary-box-font-family: "ui" !default;
$theme-summary-box-link-color: default !default;
$theme-summary-box-text-color: default !default;

// Table
$theme-table-border-color: "ink" !default;
$theme-table-header-background-color: "base-lighter" !default;
$theme-table-header-text-color: default !default;
$theme-table-stripe-background-color: "base-lightest" !default;
$theme-table-stripe-text-color: default !default;
$theme-table-text-color: default !default;
$theme-table-sorted-header-background-color: "accent-cool-light" !default;
$theme-table-sorted-background-color: "accent-cool-lighter" !default;
$theme-table-sorted-stripe-background-color: "blue-cool-10v" !default;
$theme-table-sorted-icon-color: default !default;
$theme-table-unsorted-icon-color: "base" !default;

// Tooltips
$theme-tooltip-background-color: "ink" !default;
$theme-tooltip-font-color: "base-lightest" !default;
$theme-tooltip-font-size: "xs" !default;
