@use "sass:math";
@use "sass:meta";
@use "uswds-core" as *;

// Calculate the width of the outer megamenu pseudo elements
@mixin outer-megamenu($header-max-width: $theme-header-max-width, $type) {
  @include u-pin("y");
  background-color: color("primary-darker");
  content: "";
  display: block;
  position: absolute;

  // Set variable for submenu max width
  $mw: smart-quote($header-max-width);
  @if meta.type-of($header-max-width) == "string" {
    // Retrieve the unit equivalent for submenu max width
    $mw: units($header-max-width);
  }

  // Create the vw equivalent for max-width:none
  @if $header-max-width == "none" {
    $mw: 100vw;
  }

  // Calculate pseudo element width
  // Subtract half the submenu width from half the viewport width
  @if $type == "standard" {
    // Add theme margin width to match padding in .usa-nav-container
    width: calc(50vw - $mw / 2 + units($theme-site-margins-width));
  } @else if $type == "extended" {
    width: calc(50vw - $mw / 2);
  }
}

.usa-megamenu {
  .usa-col {
    // Flex grow to take up available width.
    // Flex shrink so long nav lines don't extend beyond viewport.
    // Finally `flex-basis: auto` for IE11.
    flex: 1 1 auto;

    @include at-media($theme-header-min-width) {
      // needs this round() to avoid a compile bug
      @include u-flex(math.round(math.div(12, $theme-megamenu-columns)));
    }
  }
}

.usa-megamenu.usa-nav__submenu {
  @include at-media($theme-header-min-width) {
    @include u-padding-x(0);
    @include u-padding-y(4);
    left: -$theme-header-logo-text-width;
    right: 0;
    width: auto;
  }

  &::before {
    @include at-media($theme-header-min-width) {
      @include outer-megamenu($type: "standard");
      right: 100%;
    }
  }

  &::after {
    @include at-media($theme-header-min-width) {
      @include outer-megamenu($type: "standard");
      left: 100%;
    }
  }
}

.usa-header--extended .usa-megamenu.usa-nav__submenu {
  &::before {
    @include at-media($theme-header-min-width) {
      @include outer-megamenu($type: "extended");
    }
  }

  &::after {
    @include at-media($theme-header-min-width) {
      @include outer-megamenu($type: "extended");
    }
  }
}
